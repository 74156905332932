<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showCreationModal">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length != 1"
        @click="showEditModal(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0"
        @click="deleteEntity"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    >
      <template #checkbox="{ record, column }">
        <span>
          <a-switch disabled v-model:checked="record[column.dataIndex]" />
        </span>
      </template>
    </a-table>
    
    <a-modal
      title="Комментарий"
      v-model:visible="modalVisible"
      :confirm-loading="modalSaving"
      @ok="saveForm"
    >
      <SpSimpleFormGenerator 
        v-if="modalVisible"
        :items="modalFormItems"
        @initialized="onFormInitialized"
      />
    </a-modal>
  </div>
</template>

<script>
import axios from "axios";
import { error, success } from "@/helpers/notifications";
import { showDeleteConfirm } from "@/helpers/modals";
import { required, email } from "@/helpers/validation";
import { createItem, itemTypes } from "@/helpers/form-generator";

import SpSimpleFormGenerator from "@/components/form-generation/sp-simple-form-generator.vue";

const baseUrl = "CommentManage";

export default {
  name: "CommentManager",
  components: {
    SpSimpleFormGenerator
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "program.title.title", title: "Программа" },
        { dataIndex: "surname", title: "Фамилия" },
        { dataIndex: "name", title: "Имя" },
        { dataIndex: "patronymic", title: "Отчество" },
        { dataIndex: "job", title: "Место работы" },
        { dataIndex: "position", title: "Должность" },
        { dataIndex: "phone", title: "Телефон" },
        { dataIndex: "email", title: "E-mail" },
        { dataIndex: "status.title", title: "Статус" },
        { dataIndex: "created", title: "Создан" },
      ],
      gridData: [],
      selectedRowKeys: [],
      selectedRows: [],

      availablePrograms: [],
      availableStatuses: [],
      modalVisible: false,
      modalSaving: false,
      modalForm: null,
      modalItem: null
    };
  },
  computed: {
    modalFormItems() {
      let items = [
        createItem("surname", "Фамилия", itemTypes.string, [required("Фамилия")]),
        createItem("name", "Имя", itemTypes.string, [required("Имя")]),
        createItem("patronymic", "Отчество", itemTypes.string, [required("Отчество")]),
        createItem("job", "Место работы", itemTypes.string),
        createItem("position", "Должность", itemTypes.string),
        createItem("phone", "Телефон", itemTypes.string),
        createItem("email", "E-mail", itemTypes.string, [required("E-mail"), email()]),
        createItem("programId", "Программа", itemTypes.reference, [required("Программа")], { options: this.availablePrograms }),
        createItem("statusId", "Статус", itemTypes.reference, [required("Статус")], { options: this.availableStatuses }),
        createItem("created", "Создан", itemTypes.date, null, { disabled: true, showTime: true }),
        createItem("comments", "Замечания/предложения", itemTypes.text, null, { type: "text" })
      ];

      if (this.modalItem) {
        items.forEach(item => {
          item.value = this.modalItem[item.name];
        });
      }

      return items;
    }
  },
  methods: {
    onFormInitialized(form) {
      this.modalForm = form;
    },
    async reload() {
      this.gridData = (await axios.get(baseUrl)).data;
    },
    rowClick(record) {
      return {
        onClick: () => this.showEditModal([record]),
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    resetModal() {
      this.modalSaving = false;
      this.modalItem = null;
    },
    showModal() {
      this.modalVisible = true;
    },
    showCreationModal() {
      this.resetModal();

      this.modalFormItems.forEach(item => item.value = null);

      this.showModal();
    },
    async showEditModal(selectedRows) {
      this.resetModal();
      this.modalItem = { ...selectedRows[0] };
      this.showModal();
    },
    async saveForm() {
      await this.modalForm.validate();

      this.modalSaving = true;
      await this.$nextTick();

      let model = { ...this.modalForm.changedState };

      try {
        if (!this.modalItem) 
          await axios.post(baseUrl, model);
        else { 
          model.id = this.modalItem.id;
          await axios.patch(baseUrl, model);
        }
        success("Сохранено");

        this.modalVisible = false;
        this.resetModal();
        await this.reload();
      } catch(e) {
        error("Ошибка при сохранении");
      }

      this.modalSaving = false;
    },
    async deleteEntity() {
      showDeleteConfirm(async () => {
        try {
          await axios.delete(baseUrl, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            data: this.selectedRowKeys,
          });
          success("Удалено");
        } catch(e) {
          error("Ошибка при удалении");
        }
        this.selectedRowKeys = [];
        await this.reload();
      });
    },
  },
  async created() {
    await this.reload();

    let programs = (await axios.get(baseUrl + "/programs")).data;
    this.availablePrograms = programs.map(x => ({ label: x.title, value: x.id }));
    
    let statuses = (await axios.get(baseUrl + "/statuses")).data;
    this.availableStatuses = statuses.map(x => ({ label: x.title, value: x.id }));
  },
};
</script>